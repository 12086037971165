import React from 'react'
import { useSearchParams } from 'react-router-dom';
import { StyledFirebaseAuth } from 'react-firebaseui';
import { fireAuthInst, fireAuthUIConfig } from 'utils/firebase';

import 'styles/UserLogin.css';

export const UserLogin = () => {
  const [searchParams] = useSearchParams();
  const redirectTo = searchParams.get('redirectTo');

  fireAuthUIConfig.signInSuccessUrl = redirectTo!;

  return (
    <div className="login_container">
      <StyledFirebaseAuth uiConfig={fireAuthUIConfig} firebaseAuth={fireAuthInst} />
    </div>
  );
}
