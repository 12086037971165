import React from "react";
import Unity, { UnityContext } from "react-unity-webgl";
import { useUserState } from "../../contexts/UserContext";
import { UserGreeting } from "components/UserGreeting";

const buildRoot = '../../game_builds/muffin_factory/';
const buildName = 'muffin_factory';

export interface MuffinFactoryBuildProps {
  buildDirectory: string
}

export const MuffinFactoryBuild = (props: MuffinFactoryBuildProps) => {
  const userState = useUserState();
  const { buildDirectory } = props;

  const buildPath = buildRoot + buildDirectory + '/Build/'

  const unityContext = new UnityContext({
    loaderUrl: `${buildPath}${buildName}.loader.js`,
    dataUrl: `${buildPath}${buildName}.data`,
    frameworkUrl: `${buildPath}${buildName}.framework.js`,
    codeUrl: `${buildPath}${buildName}.wasm`
  });

  return (
    <>
      <UserGreeting user={userState.user} />
      <p className='js-project-name'>Codename: Muffin Factory</p>
      <Unity 
        unityContext={unityContext}
        style={{
          height: "100%",
          width: '60vw',
          border: "1px solid #ff5e48",
          filter: "drop-shadow(10px 10px 40px black)",
          margin: "auto",
        }}
      />
    </>
  );
}
