import React from 'react'
import { UserSignOut } from './UserSignOut';
import { User, useUserState } from '../contexts/UserContext';

interface UserGreetingProps {
  user: User | null
}

export const UserGreeting = (props: UserGreetingProps) => {
  const { user } = props;
  if(!user) {
    return null;
  }

  return (
    <h1 className='js-user-greeting'>Hello {user.displayName}!</h1>
  );
};
