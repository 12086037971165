import React from "react";

export const AccessDenied = () => {
  return (
    <div>
      <h1>Access Denied</h1>
      <p>Oops! You don't have access to view this page. Please contact the site admins for more information. Cheers!</p>
    </div>
  );
};
