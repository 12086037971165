import React from 'react';
import { AuthenticatedRoute } from './AuthenticatedRoute'
import { Routes, Route } from 'react-router-dom';

import { HomePage } from '../components/HomePage'
import { MuffinFactoryBuild } from '../components/muffin_factory/MuffinFactoryBuild';
import { UserLogin } from '../components/UserLogin';
import { AccessDenied } from '../components/AccessDenied';

export const AppRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<HomePage />} />

      <Route path="/login" element={ <UserLogin />} />
      <Route path="/access_denied" element={ <AccessDenied /> } />

      <Route path="/muffin_factory/builds/v0.2" element={<AuthenticatedRoute requiredPermissions={['alphaBuild']} /> }>
          <Route path="/muffin_factory/builds/v0.2" element={<MuffinFactoryBuild buildDirectory='v0.2'/>} />
      </Route>

      <Route path="/muffin_factory/builds/v0.3" element={<AuthenticatedRoute requiredPermissions={['alphaBuild']} /> }>
          <Route path="/muffin_factory/builds/v0.3" element={<MuffinFactoryBuild buildDirectory='v0.3'/>} />
      </Route>

      <Route path="/muffin_factory/builds/latest" element={<AuthenticatedRoute requiredPermissions={['alphaBuild']} /> }>
        <Route path="/muffin_factory/builds/latest" element={<MuffinFactoryBuild buildDirectory='latest'/>} />
      </Route>
    </Routes>
  );
}
