import { fireStoreDB } from "../utils/firebase";
import "regenerator-runtime";

export async function getPermissionsForRole(role: string) {
  const rolePermissionResults = await fireStoreDB.collection('permissions').where("role", "==", role).get();

  if(rolePermissionResults.docs.length !== 1) {
    throw new Error(`There should be exactly one set of permissions for a role.\nFound ${rolePermissionResults.docs.length} results\nRole: ${role}`);
  }

  return rolePermissionResults.docs[0].data().permissions;
}
