// Import the functions you need from the SDKs you need
import firebase from "firebase/compat/app";
import "firebase/compat/analytics";
import "firebase/compat/firestore";
import 'firebase/compat/auth'

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyChIZeTdgdRVkrZCfa32LQUVZ8qbuwmVGw",
  authDomain: "cheat-codes-entertainmen-e7dcd.firebaseapp.com",
  projectId: "cheat-codes-entertainmen-e7dcd",
  storageBucket: "cheat-codes-entertainmen-e7dcd.appspot.com",
  messagingSenderId: "1019592242320",
  appId: "1:1019592242320:web:2aa5a414c961dc83a11415",
  measurementId: "G-YLKC8G823D"
};

// The configuration for the firebase auth ui
let fireAuthUIConfig = {
  signInFlow: 'popup',
  signInSuccessUrl: 'invalid_url',
  signInOptions: [
    // Leave the lines as is for the providers you want to offer your users.
    firebase.auth.GoogleAuthProvider.PROVIDER_ID,
    firebase.auth.EmailAuthProvider.PROVIDER_ID
  ],
  // // tosUrl and privacyPolicyUrl accept either url string or a callback
  // // function.
  // // Terms of service url/callback.
  // tosUrl: '<your-tos-url>',
  // // Privacy policy url/callback.
  // privacyPolicyUrl: function() {
  //   window.location.assign('<your-privacy-policy-url>');
  // }
};

let fireAppInst: firebase.app.App;
let fireAnalyticsInst: firebase.analytics.Analytics;
let fireAuthInst: firebase.auth.Auth;
let fireStoreDB: firebase.firestore.Firestore;

// Initialize Firebase
export const FirebaseInit = () => {
  fireAppInst = firebase.initializeApp(firebaseConfig);

  fireAnalyticsInst = firebase.analytics();
  fireStoreDB = firebase.firestore();
  fireAuthInst = firebase.auth();
}

export {fireAppInst, fireAnalyticsInst, fireStoreDB, fireAuthInst, fireAuthUIConfig};
