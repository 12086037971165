import React from "react";
import { Outlet, Navigate, useLocation } from "react-router-dom";
import { useUserState, UserState } from "../contexts/UserContext";

interface AuthenticatedRouteProps {
  requiredPermissions: string[]
}

export const AuthenticatedRoute = ({requiredPermissions} : AuthenticatedRouteProps) => {
  const userState = useUserState();
  const location = useLocation();
  const hasPermission = HasPermission(requiredPermissions, userState);

  if(!userState.isAuthenticated) {
    return <Navigate to={`/login?redirectTo=${location.pathname}`} />;
  } else if (!hasPermission) {
    return <Navigate to="/access_denied"/>;
  } else {
    return <Outlet />;
  }
}

function HasPermission(requiredPermissions : string[], userState: UserState) {
  if(!userState.isAuthenticated) {
    return false;
  }

  if(!requiredPermissions) {
    return true;
  }

  const user = userState.user!;
  const userPermissions: string[] = [];

  Object.entries(user.permissions).forEach(([key, value]) => {
    if(value) {
      userPermissions.push(key);
    }
  });

  const hasPermission = requiredPermissions.every((required) => {
    return userPermissions.includes(required);
  });

  return hasPermission;
}
