import React from 'react';
import { BrowserRouter } from 'react-router-dom';

import './App.css';
import { Header } from './components/Header';

import { useUserState } from "./contexts/UserContext";
import { AppRoutes } from './routes/AppRoutes';

export function App() {
  const userState = useUserState();

  return (
    <div className="App">
      <BrowserRouter>
        <Header/>
        { userState.loading ? <h1>LOADING...</h1> : <AppRoutes/> }
      </BrowserRouter>
    </div>
  );
}

export default App;
