import React from 'react';
import { useState, useEffect, useContext, createContext } from 'react';
import { fireAuthInst } from "../utils/firebase";
import { getOrCreateUser } from "../api/users"
import { getPermissionsForRole } from '../api/permissions';
import "regenerator-runtime"

import firebase from "firebase/compat/app";

export type User = firebase.firestore.DocumentData & firebase.User;

export interface UserState {
  user: User | null,
  error?: Error | null;
  loading: boolean;
  isAuthenticated: boolean;
}

export const UserContext = createContext<UserState>({
  user: null,
  error: null,
  loading: true,
  isAuthenticated: false
});

type UserContextProps = {
  children: React.ReactNode | null;
};

export const UserContextProvider = (props: UserContextProps) => {
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState<User | null>(null);
  const [error, setError] = useState<Error>();
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  
  //occurs on mount and updates
  useEffect(() => {
    setLoading(true);

    const listener = fireAuthInst.onAuthStateChanged(async (authUser) => {
      if(authUser) {
        let userData = await getOrCreateUser(authUser);
        if(userData) {
          userData.permissions = await getPermissionsForRole(userData.role);
        }

        setUser({...userData, ...JSON.parse(JSON.stringify(authUser))});
        setIsAuthenticated(true);
      } else {
        setUser(null);
        setIsAuthenticated(false);
      }

      setLoading(false);
    },
      setError
    );

    //returning a function from useEffect causes it to be called by react on unmount.
    //In this case, we want to unsubscribe from the state change callback when this unmounts
    return function cleanup() { 
      listener()
    };
  }, []);

  return <UserContext.Provider value={{ user, error, loading, isAuthenticated}} {...props}/>
}

export const useUserState = () => {
  const context = useContext(UserContext);

  return context;
}
