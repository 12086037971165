import { fireStoreDB } from "../utils/firebase";
import firebase from "firebase/compat/app";

export async function getOrCreateUser(authUser: firebase.User) {
  const userDocRef = fireStoreDB.collection('users').doc(authUser.uid);

  let userDoc = await userDocRef.get();
  if(!userDoc.exists) {
    await userDocRef.set({
      role: "user",
      email: authUser.email
    });

    userDoc = await userDocRef.get();
  }

  const data = userDoc.data()!;
  if(data.email !== authUser.email) {
    await userDocRef.update( {
      email: authUser.email
    });

    data.email = authUser.email;
  }

  return data;
}
