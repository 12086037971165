import React from 'react'

import { fireAuthInst } from '../utils/firebase';
import { useNavigate } from 'react-router-dom';

export const UserSignOut = () => {
  const navigate = useNavigate();

  return (
    <button onClick={ () => {
        fireAuthInst.signOut();
        navigate('/', { replace: true });
      }
    }>
      SIGN OUT
    </button>
  );
}
