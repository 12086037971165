import React from "react";
import { Link } from "react-router-dom";

import logo from '../assets/leaning_logo.png';

export const Header = () => {
  return (
    <div className="App-header">
      <Link to="/">
        <img src={logo} className="App-logo" alt="logo" />
      </Link>
    </div>
  )
}
