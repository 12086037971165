import React from 'react'
import { UserSignOut } from './UserSignOut';
import { User, useUserState } from '../contexts/UserContext';
import { UserGreeting } from 'components/UserGreeting';

export const HomePage = () => {
  const userGreeting = (user : User | null) => {
    if(!user) {
      return null;
    }

    return (
      <div>
        <UserGreeting user={user}/>
        <UserSignOut/>
      </div>
    );
  };

  const welcomeMessage = () => {
    return (
      <p className='js-welcome-message'>Cheat Codes Entertainment is coming soon...</p>
    );
  };

  const { user } = useUserState()!;

  return (
    <>
      { userGreeting(user) }
      { welcomeMessage() }
    </>
  );
};
